/* eslint-disable max-len */

// Local Storage Keys
export const ACCESS_TOKEN_KEY = '_ut'
export const TEMP_ACCESS_TOKEN_KEY = '_tut'
export const ONBOARDING_STORAGE_KEY = 'onboarding'
export const REGISTRATION_STORAGE_KEY = 'registration'
export const REGISTRATION_KIOSK_STORAGE_KEY = 'kiosk_registration'
export const CURRENT_TENANT_ID = '_tid'
export const CURRENT_TENANT_SLUG = '_tslug'
export const CURRENT_USER_ID = '_cid' // njsscan-ignore: node_username
export const PLACE_YOUR_ORDER_FORM_KEY = 'placeYourOrderForm'
export const EMAIL_KEY = 'email'
export const VERIFICATION_CODE_KEY = '_vc'

export const COLORS = Object.freeze({
  NAVY: '#649372',
  PRIMARY: '#2A3C56',
  GRAY_300: '#B2B2B2',
  WHITE: 'white',
})

export const GLO449_CODE = 'GLO449'
export const GLO599_CODE = 'GLO599'
export const TMG_CODE = 'TMG'
export const TMG_PRIVATE_PAY_CODE = 'TMG75'
export const PRIME_CODE = 'PRIME'
export const PRIME_PRIVATE_PAY_CODE = 'PRIME75'

export const ROLES = Object.freeze({
  client: 'client',
})

export const ORIENTATION_MEETING_URL_INVOY =
  'https://calendly.com/invoy/orientation'

export const LEARN_MORE_VIDEO_URL =
  '//player.vimeo.com/video/788096407?h=5392f69cee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

export const TESTIMONIAL_VIDEO_URL =
  '//player.vimeo.com/video/750535394?h=2a808ed2b0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

export const DEFAULT_BOFA_REFERRAL_CODE = 'BOFA'
export const DEFAULT_INSURANCE_REFERRAL_CODE = 'INSURANCE'
export const DEFAULT_SELFPAY_REFERRAL_CODE = 'HELLO'
export const DEFAULT_IIB_REFERRAL_CODE = 'CARE50'

export const BMI_TRESHOLD = 25

export const COVERAGE_TYPE_OPTIONS = {
  selfpay: 'self_pay',
  enterprise: 'enterprise',
  health: 'health_insurance',
}

export const ENTERPRISE_COVERAGE_OPTIONS = {
  employer: 'employer',
  household: 'household',
}

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please contact support@invoy.com'

export const STATES_LIST = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington DC',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Puerto Rico',
  'Guam',
  'US Virgin Islands',
]

export const DEFAULT_COVERAGE_DETAILS = {
  healthPlans: [
    { name: 'Aetna', value: 'Aetna', disclaimer: null },
    {
      name: 'Blue Cross Blue Shield (BCBS)',
      value: 'Blue Cross Blue Shield (BCBS)',
      disclaimer: null,
    },
    {
      name: 'Capital Health Plan (CHP)',
      value: 'Capital Health Plan (CHP)',
      disclaimer: null,
    },
    { name: 'Cigna', value: 'Cigna', disclaimer: null },
    { name: 'Humana', value: 'Humana', disclaimer: null },
    {
      name: 'Humana Medicare (MCR)',
      value: 'Humana Medicare (MCR)',
      disclaimer: null,
    },
    { name: 'Medicare (MDCR)', value: 'Medicare (MDCR)', disclaimer: null },
    { name: 'Meritain', value: 'Meritain', disclaimer: null },
    {
      name: 'United Health Care (UHC)',
      value: 'United Health Care (UHC)',
      disclaimer: null,
    },
    {
      name: 'United Health Care Medicare (UHCMCR)',
      value: 'United Health Care Medicare (UHCMCR)',
      disclaimer: null,
    },
    { name: 'Wellcare', value: 'Wellcare', disclaimer: null },
  ],
  healthcareProviders: [
    { name: 'Dr. Williams', value: 'Dr. Williams' },
    { name: 'Dr. Morse', value: 'Dr. Morse' },
  ],
}
